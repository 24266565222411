<template>
<div id="app_div_join">
    <van-nav-bar
    left-text="返回"
    right-text="切换账号"
    @click-right="onClickRight"
    left-arrow
    @click-left="isBlanceUser?$router.go(-1):$router.replace('/bank/wallet')"/>
<div class="icon_div">
    <div class="icon_body">
        <van-icon name="gold-coin" color="#f6c459" size="60"  />
    </div>
</div>
<div class="body_div">
    <div>我的余额</div>
    <div>￥{{userBalance}}</div>
</div>
<van-action-sheet
    v-model="show"
    :actions="actions"
    @select="onSelect"
    cancel-text="取消"
    description="充值方式"
    close-on-click-action/>
<div class="footer_div">
    <div class="footer_btn_div">
        <div><van-button type="primary" @click="showDepositType" block>充值</van-button></div>
        <div style="margin-top:15px;"><van-button @click="$router.push({path:'/bank/withdraw',query:{type:1}})" block>提现</van-button></div>
    </div>
</div>
</div>
</template>
<script>
import { Button,NavBar,Icon,ActionSheet,Dialog} from 'vant';
import {getAccountBalance} from '../../api/common'
import {allinpayUser} from '../../store/allinpay'
import {balanceUser} from '../../store/personal'
import {userLogout} from '../../api/login'
import {logout,loginUrlSotre} from '../../store/token'
export default({
    components:{
        [Button.name]: Button,
        [NavBar.name]: NavBar,
        [Icon.name]: Icon,
        [ActionSheet.name]: ActionSheet,
        [Dialog.Component.name]: Dialog.Component,
    },
    data(){
        return{
            show:false,
            actions:this.$enum.payTypeEnum,
            userBalance:0.00,
            user:'',
            isBlanceUser:false
        };
    },
    methods:{
        onClickRight() {
            this.$dialog.confirm({
                title: '提示',
                message: '确认退出当前账号？',
            })
            .then(() => {
                userLogout().then(res=>{
                    this.$toast({type:res.status?'success':'fail',message:res.message});
                }).finally(()=>{
                    loginUrlSotre.set("/personal?go_index=/bank/balance")
                    logout();
                })
            })
            .catch(() => {});
        },
        onSelect(val){
            if(val.value == this.$enum.payTypeEnum[1].value){
                this.$router.push({path:'/bank/withdraw',query:{type:0,payType:1}})
            }else{
                this.$toast.fail("微信支付暂不支持")
                return;
            }
            // else if(val.value == this.$enum.payTypeEnum[0].value){
            //     this.$router.push({path:'/bank/withdraw',query:{type:0,payType:0}})
            // }
        },
        showDepositType(){
            this.show = true;
        }
    },
    mounted(){
        this.isBlanceUser = !!balanceUser.get();
        document.getElementsByTagName('body')[0].style.backgroundColor='#fff';
        var user = allinpayUser.get();
        var body = {
            userId:user.userId,
            userSource:user.userSource
        }
        this.$toast.loading({duration: 0,forbidClick: true,message: '加载中'});
        getAccountBalance(body).then(res=>{
            this.$toast.clear();
            this.userBalance =parseFloat(res.data).toFixed(2);
        }).catch(err=>{
            this.$toast.clear();
            console.error(err);
        })
    },
    
})
</script>
<style scoped>
@import './balance.css';
</style>